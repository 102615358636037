import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { lazy, Suspense, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Sidebar from "./Container/Sidebar";
import LoginPage from "./Components/LoginPage/LoginPage";
  
const Navlanding = lazy(() =>
  import("./Components/NavigationApp/navlanding.jsx")
);

function App() {
  const [showNav, setShowNav] = useState(false);
  const [IsMobileNavShow, setIsMobileNavShow] = useState(false);
  const [IsMobileNavShowBackDrop, setIsMobileNavShowBackDrop] = useState(false);

  useEffect(() => {
    const loader = document.getElementById("spinner-loader");

    loader.classList.add("fadeOut");
  });
  const toggleNav = (data) => {
    setShowNav(!data);
  };

  return (
    <div
      className={`main-container ${showNav ? "showNav" : ""}   ${
        IsMobileNavShow ? "mobileView" : ""
      }`}>
      <div id="spinner-loader">
        <div className="spinner"></div>
      </div>
     
      <div
        className={`navToggolForMobileBAckSkin d-md-none ${
          IsMobileNavShowBackDrop === false ? "hide" : "show"
        }`}
        onClick={(e) => (
          setIsMobileNavShowBackDrop(false), setIsMobileNavShow(false)
        )}></div>
      <ToastContainer
        autoClose={800}
        pauseOnFocusLoss={false}
      />
      {/* <Sidebar toggleNav={toggleNav} /> */}
      <div className="content-area-wrap">
        <Suspense fallback={<p className="loadding">Loading...</p>}>
          <Routes>
            <Route
              path="/"
              element={
                window?.IS_SSO_ENABLED === "TRUE" ? (
                  <Navigate
                    to="/app"
                    replace
                  />
                ) : (
                  <LoginPage />
                )
              }
            />
            {/* <Route path="/app" element={<Navlanding />} /> */}
            <Route
              path="/app"
              element={<Navlanding />}
            />
            {/* <Route path="/markets-apps" element={<MarketsAppsPage />} />
              <Route path="/banking-apps" element={<BankingAppsPage />} />
              <Route path="/payments-apps" element={<PaymentsAppsPage />} /> */}
          </Routes>
        </Suspense>
      </div>
    </div>
  );
}

export default App;
