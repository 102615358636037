import React, { useState, useRef, useEffect, memo } from "react";
import "./Dropdown.css";
import { useNavigate } from "react-router-dom";
import { logout } from "../../keycloak";
import { MdKeyboardArrowDown } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { FaUser } from "react-icons/fa6";
import { TbLogout } from "react-icons/tb";
const Dropdown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const navigate = useNavigate();

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    // sessionStorage.setItem("authServerId", "");
    // sessionStorage.setItem("refresh_token", "");
    // localStorage.removeItem("userName");
    // localStorage.removeItem("name");

    // Clear all localStorage data
    localStorage.clear();

    // Clear all sessionStorage data
    sessionStorage.clear();

    console.log("All localStorage and sessionStorage data have been removed.");
    window?.IS_SSO_ENABLED === "TRUE" ? logout() : navigate("/");
  };

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <div
        // className="profile-wrap d-flex align-center"
        className=""
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bg-primary text-white d-flex px-3 px-md-4  py-md-3 py-2 rounded-full border-0 cursor-pointer">
          <FaRegUser className="me-3" />

          <MdKeyboardArrowDown />
        </div>

        {/* <div className="profile-name">{sessionStorage.getItem("userName")}</div> */}
      </div>
      <ul className={`dropdown-menu-head ${isOpen ? "open" : ""}`}>
        {/* <li className="menu-opt d-flex align-center">
           // <img alt="" src={profileImg} /> 
          Profile
        </li> */}
        <li>
          <div className="d-flex items-center">
            <div className="icon bg-[#E9E9E9] w-[4rem] h-[4rem] rounded-full flex overflow-hidden">
              <FaUser className="size-[3.5rem] text-[#C5C6C6] m-auto relative -bottom-2" />
            </div>
            <div className="text text-start ps-3">
              <p className="mb-0 text-[1.25rem]">
                <b className="text-[#2B2A29] font-[500]">
                  {localStorage.getItem("name")}
                </b>
              </p>
              <p className="mb-0 text-[1rem]">
                <a
                  href={`mailto:${localStorage.getItem("emailId")}`}
                  className="text-[#898989] "
                >
                  {localStorage.getItem("emailId")}
                </a>
              </p>
            </div>
          </div>
        </li>
        <li>
          <hr />
        </li>
        <li
          className="d-flex items-center justify-center cursor-pointer text-[#2B2A29] font-[500]"
          onClick={handleLogout}
        >
          <TbLogout className="size-[1.7rem]  text-[#636262] me-1" />
          <span className=" hover:text-primary transition-all duration-300 text-[1.25rem]  ">
            Sign Out
          </span>
        </li>
      </ul>
    </div>
  );
};

export default memo(Dropdown);
