export const KEYCLOAK = "/keycloak";

// SSO
export const SSO_REALM = "canvasai";
export const SSO_CLIENT_ID = "canvasai-chat";

//Static
export const NO_APPS = "There are no ";

export const Category_App = "Apps.";
export const Place_Holder_apps = "Search all apps...";

export const Tire_List_Heading = [
  // { text: "All Apps", status: true, tag: "" },
  { text: "Contract.AI", status: true, tag: "LNTCONTRACT" },
  { text: "Engineering.AI", status: false, tag: "LNTENGG" },
  { text: "Project Execution.AI", status: false, tag: "LNTPE" },
  {
    text: "Finance.AI",
    status: false,
    tag: "LNTFINANCE",
    subCategory: [
      { title: "Operational", isActive: true },
      { title: "Compliance", isActive: false },
      { title: "Stakeholder", isActive: false },
    ],
  },
  { text: "Supply Chain.AI", status: false, tag: "LNTSC" },
  { text: "Vision.AI", status: false, tag: "LNTVSN" },
  { text: "Human Resource.AI", status: false, tag: "LNTHR" },
  { text: "Tech.AI", status: false, tag: "LNTTEC" },
  { text: "Knowledge.AI", status: false, tag: "LNTKNLD" },
  { text: "Explore.AI", status: false, tag: "LNTEXP" },
];

export const Heading_Title = "L&T Cognitive Services";
export const Heading_Caption = "Powered by Canvas.AI and Fosfor";
